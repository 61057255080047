let prod = true;

export const BASE_URl = prod ? "https://2024.icode.org" : "https://2023.dev.icode.org";

export const postApiCall = async (apiEndpoints, sendData) => {
    return await fetch(BASE_URl + apiEndpoints, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: sendData
    })
        .then(async response => {
            // console.log(response);
            if (response.status !== 200) {
                throw new Error("Not 200");
            } else {
                return await response.json();
            }
        })
        .then(data => {
            // console.log("DATA", data);
            return data;
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
}

export const postApiCallStringyfy = async (apiEndpoints, sendData) => {

    return await fetch(BASE_URl + apiEndpoints, {
        method: "POST",
        // mode: 'same-origin',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify(sendData)
    })
        .then(async response => {
            // console.log(response);
            if (response.status !== 200) {
                throw new Error("Not 200");
            } else {
                return await response.json();
            }
        })
        .then(data => {
            // console.log("DATA", data);
            return data;
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
}