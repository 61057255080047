import React from 'react'
import '../css/style.css'
import { Col, Container, Row } from 'react-bootstrap'

const TopThreeRanking = ({ data }) => {
    return (
        <>
            {/* {data.length !== 0 && ( */}
            <Container>
                {data.length !== 0 && <Row className='top-three-container container'>
                    {/* <Col className='reversing-top-two'> */}
                    {data[1] && (
                        data[1].emi_type === "premium" &&
                        <Col className='card-box extra-margin'>
                            <Row>2nd</Row>
                            <Row><img src={data[1].profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                            <Row className='course-name-top-three ranklist-student-name'>{data[1].first_name}  {data[1].last_name}</Row>
                            <Row className='Student-name-top-three ranklist-course-name'>{data[1].course_name}</Row>
                            <Row className='Student-name-top-three ranklist-school-name'>{data[1].site_name}</Row>
                            <Row className='Student-name-top-three ranklist-school-name'>Score: {data[1].highest_score}</Row>
                        </Col>)}
                    {data[0] && (
                        data[0].emi_type === "premium" &&
                        <Col className='card-box'>
                            <Row>1st</Row>
                            <Row><img src={data[0].profilepic} alt="profile" width={100} height={100} className='top-three-image' /></Row>
                            <div className='extra-margin alignment-style'>
                                <Row className='course-name-top-three ranklist-student-name'>{data[0].first_name}  {data[0].last_name}</Row>
                                <Row className='Student-name-top-three ranklist-course-name'>{data[0].course_name}</Row>
                                <Row className='Student-name-top-three ranklist-school-name'>{data[0].site_name}</Row>
                                <Row className='Student-name-top-three ranklist-school-name'> Score: {data[0].highest_score}</Row>

                            </div>

                        </Col>)}
                    {/* </Col> */}
                    {data[2] && (
                        data[2].emi_type === "premium" &&
                        <Col className='card-box extra-margin'>
                            <Row>3rd</Row>
                            <Row><img src={data[2].profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                            <Row className='course-name-top-three ranklist-student-name'>{data[2].first_name}  {data[2].last_name}</Row>
                            <Row className='Student-name-top-three ranklist-course-name'>{data[2].course_name}</Row>
                            <Row className='Student-name-top-three ranklist-school-name'>{data[2].site_name}</Row>
                            <Row className='Student-name-top-three ranklist-school-name'>Score: {data[2].highest_score}</Row>
                        </Col>)}
                </Row>}
            </Container>
            {/* )} */}
        </>
    )
}

export default TopThreeRanking