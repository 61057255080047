import React, { useState } from 'react'
import '../css/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
const Header = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            props.onClick();
        }
    };
    return (
        <Container>
            <Row className='head-top-container'>
                <Row className='header-container'>
                    <Col className='header-icon-container'>
                        <img src="/logo4.png" width={212} height={85} alt="icode" className='header-icon' />
                    </Col>
                    <Col className='header-search-input'>
                        <div className='search-btn-input'>
                            <input type='text' className='form-control' placeholder='Search Your Name' onChange={(e) => {
                                props.setSearchTerm(e.target.value)
                            }}
                                onKeyPress={handleKeyPress} />
                            <button type='btn' onClick={() => {
                                props.onClick()
                            }} class="search-btn"><FontAwesomeIcon icon={faSearch} /></button>
                        </div>
                    </Col>
                </Row>
                <Col className='header-heading'> ICode Top Rankers</Col>
            </Row>
        </Container>
    )
}

export default Header