import React, { useEffect, useState } from "react";
import './leaderboard.css';
import Topranker from './components/National/Topranker';
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { postApiCallStringyfy } from "./utils/api";
import { useParams } from "react-router-dom";
import { countryData } from "./utils/Constants";
import LoadingOverlay from "react-loading-overlay";
import TopThreeRanking from "./components/TopThreeRanking";
import './css/style.css'
import Header from "./components/Header";
import HeaderTab from "./components/HeaderTab";
import OtherRanks from "./components/OtherRanks";
import { CircleLoader } from 'react-spinners';

const National = () => {
  // console.log("adas", val);
  const options = [
    { value: "27", label: "BLOCKLY BEGINNER" },
    { value: "26", label: "BLOCKLY ADVANCE" },
    { value: "7", label: "PYTHON BEGINNER" },
    { value: "84", label: "PYTHON ADVANCE" },
  ];

  const { countryName } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  const [courseValue, setCourseValue] = useState("27")
  const [stateValue, setStateValue] = useState("")
  const [cityValue, setCityValue] = useState("")
  const [schoolValue, setSchoolValue] = useState("")
  const [gradeValue, setGradeValue] = useState("")


  const [countryCode, setCountryCode] = useState("")

  const getData = async () => {
    setOffset(100);
    setIsLoading(true);
    const countryList = countryData;
    for (let i = 0; i < countryList.length; i++) {
      if (countryName.toLowerCase() === countryList[i].value.toLowerCase()) {
        const res = await postApiCallStringyfy('/leaderboard/getRank', {
          "country_id": countryList[i].id,
          "course_id": courseValue,
          "state_id": stateValue,
          "city_id": cityValue,
          "site_id": schoolValue,
          // "grade": gradeValue,
          "offset": "0",
          "limit": "100"
        });
        setCountryCode(countryList[i].id)
        res[0].toppers.length === 0 ? setHasMore(false) : setHasMore(true)
        setData(res[0].toppers);
      }
    }
    setIsLoading(false);

  }
  useEffect(() => {
    getData()
  }, [])


  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const limit = 100;


  const loadData = async () => {
    const res = await postApiCallStringyfy('/leaderboard/getRank', {
      "country_id": countryCode,
      "course_id": courseValue,
      "state_id": stateValue,
      "city_id": cityValue,
      "site_id": schoolValue,
      // "grade": gradeValue,
      "offset": offset.toString(),
      "limit": limit.toString()
    });
    // console.log(res);
    setData([...data, ...res[0].toppers]);
    setHasMore(res[0].toppers.length === limit);
    setOffset(((offset) + 100));
  };

  // search
  const [searchTerm, setSearchTerm] = useState("")
  const [isSearchTerm, setIsSearchTerm] = useState(false)
  const searchFunction = async () => {
    setIsLoading(true);

    const res = await postApiCallStringyfy('/leaderboard/getRank', {
      "search_term": searchTerm,
      "country_id": countryCode,
      "course_id": courseValue,
    });
    setData(res[0].toppers);
    setIsSearchTerm(true)
    setIsLoading(false);
  }

  return (
    <>

      {/* <LoadingOverlay className="my-loading-overlay" active={isLoading} text='Loading your content...' spinner> */}
      <Header setSearchTerm={setSearchTerm} onClick={searchFunction} />
      <HeaderTab
        countryCode={countryCode}
        setCourseValue={setCourseValue}
        courseValue={courseValue}
        setStateValue={setStateValue}
        stateValue={stateValue}
        setCityValue={setCityValue}
        cityValue={cityValue}
        setSchoolValue={setSchoolValue}
        schoolValue={schoolValue}
        setGradeValue={setGradeValue}
        gradeValue={gradeValue}
        setIsLoading={setIsLoading}
        onClick={getData} />
      {/* {data.length !== 0 && ( */}
      {isLoading ? (
        <div style={{
          display: 'flex',
          justifyContent: "center",
          alignItems: "center"
        }}>
          <CircleLoader size={80} color="#fff" />
        </div>
      ) : (
        <Container className="body-container">
          {isSearchTerm === false ? <TopThreeRanking data={data} /> : null}
          <OtherRanks
            data={data}
            setData={setData}
            onClick={loadData}
            hasMore={hasMore}
            searchTerm={searchTerm}
          />

        </Container>)}

      <div className="footer">
        <a
          href="https://icode.org/contact/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          {" "}
          <p
            style={{ color: "white", marginRight: "10px", marginTop: "20px" }}
          >
            Contact Us{" "}
          </p>
        </a>
        <p>
          <img  src="/footer.png" alt="footer" />
        </p>
      </div>


      {/* <Container className="bg-dark">
          <Row className="header">
            <Col >
              <img src="/logo2.png" width={212} height={120} alt="icode" />
            </Col>
            <Col className="dropdown">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="BLOCKLY BEGINNER"
                name="color"
                onChange={(val) => handleOptionChange(val.value)}
                options={options}
              />
            </Col>
          </Row>
        </Container>
        <h1 className="heading">National LEADERBOARD</h1>
        <Topranker data={data} /> */}



      {/* </LoadingOverlay> */}
    </>
  );
};

export default National;
