import React, { useEffect, useState } from 'react'
import '../css/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import Select from "react-select";
import { postApiCall, postApiCallStringyfy } from '../utils/api';


const options = [
    { value: "27", label: "BLOCKLY BEGINNER" },
    { value: "26", label: "BLOCKLY ADVANCE" },
    { value: "7", label: "PYTHON BEGINNER" },
    { value: "29", label: "PYTHON ADVANCE" },
];

const gradeList = [
    { "value": "1" },
    { "value": "2" },
    { "value": "3" },
    { "value": "4" },
    { "value": "5" },
    { "value": "6" },
    { "value": "7" },
    { "value": "8" },
    { "value": "9" },
    { "value": "10" },
    { "value": "11" },
    { "value": "12" },

];

const HeaderTab = (props) => {
    const [isNational, setIsNational] = useState(true);
    const [isState, setIsSate] = useState(false);
    const [isCity, setIsCity] = useState(false);
    const [isSchool, setIsSchool] = useState(false);


    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);

    useEffect(() => {
        getData()
    }, [props.countryCode])

    const getData = async () => {
        const res = await postApiCall('/ncl/getStates', "country_id=" + props.countryCode);
        setStateList(res[0]);
    }

    async function onStateChange(val) {
        setStateId(val)
        const res = await postApiCallStringyfy("/ncl/getNewCities", {
            "state_id": val,
            "country_id": props.countryCode,
        })
        console.log(res);
        setCityList(res)
    }
    async function onCityChange(val) {
        const res = await postApiCallStringyfy("/ncl/getNewSchools", {
            "city_id": val,
            "state_id": stateId,
            "country_id": props.countryCode,
        })
        setSchoolList(res)
    }

    const handeNationalRanking = () => {
        props.setCourseValue("")
        props.setStateValue("")
        props.setCityValue("")
        props.setGradeValue("")
        props.setSchoolValue("")
        setIsSate(false)
        setIsCity(false)
        setIsSchool(false)
        setIsNational(true)
    }

    const handeStateRanking = () => {
        props.setCourseValue("")
        props.setStateValue("")
        props.setCityValue("")
        props.setGradeValue("")
        props.setSchoolValue("")
        setIsSate(true)
        setIsCity(false)
        setIsSchool(false)
        setIsNational(false)
    }

    const handeCityRanking = () => {
        props.setCourseValue("")
        props.setStateValue("")
        props.setCityValue("")
        props.setGradeValue("")
        props.setSchoolValue("")
        setIsSate(false)
        setIsCity(true)
        setIsSchool(false)
        setIsNational(false)
    }

    const handeSchoolRanking = () => {
        props.setCourseValue("")
        props.setStateValue("")
        props.setCityValue("")
        props.setGradeValue("")
        props.setSchoolValue("")
        setIsSchool(true)
        setIsSate(false)
        setIsCity(false)
        setIsNational(false)
    }

    const [stateId, setStateId] = useState("")

    return (
        <Container>
            {/* <Row className='tab-container'>
                <Col>
                    <button className='each-tab' onClick={() => handeNationalRanking()}>
                        <img src="/Leaderboard/Global.svg" width={29} height={120} alt="icode" />
                        <div className='flex-column'>
                            <p>National Ranking</p>
                        </div>
                    </button>
                </Col>
                <Col>
                    <button className='each-tab' onClick={() => handeStateRanking()}>
                        <img src="/Leaderboard/State.svg" width={29} height={120} alt="icode" />
                        <div className='flex-column'>
                            <p>State Ranking</p>
                        </div>
                    </button>
                </Col>
                <Col>
                    <button className='each-tab' onClick={() => handeCityRanking()}>
                        <img src="/Leaderboard/City.svg" width={29} height={120} alt="icode" />
                        <div className='flex-column'>
                            <p>City Ranking</p>
                        </div>
                    </button>
                </Col>
                <Col>
                    <button className='each-tab' onClick={() => handeSchoolRanking()}>
                        <img src="/Leaderboard/School.svg" width={29} height={120} alt="icode" />
                        <div className='flex-column'>
                            <p>School Ranking</p>
                        </div>
                    </button>
                </Col>
            </Row> */}

            <Row>

                {
                    isNational && (
                        <Row className='search-container'>

                            <select
                                className='select-design'
                                value={props.courseValue} 
                                onChange={(e) => props.setCourseValue(e.target.value)}>
                                <option value="">Select Course</option>
                                {options.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                            {/* <Col className="dropdown-style">
                                <Select
                                    className="search-select-style"
                                    classNamePrefix="select"
                                    placeholder="Select Course"
                                    name="color"
                                    onChange={(val) => props.setCourseValue(val.value)}
                                    options={options}
                                />
                            </Col> */}

                            <button onClick={() => {
                                 if( props.courseValue === ""){
                                    return (
                                        alert("Provide course field!")
                                    )
                                }
                                props.onClick()}} className='header-tab-search-btn'>Search</button>
                        </Row>
                    )
                }
                {
                    isState && (
                        <Row className='search-container'>

                            <select
                                className='select-design'
                                value={props.stateValue} 
                                onChange={(e) => {
                                    props.setCityValue("");
                                    props.setStateValue(e.target.value);
                                    onStateChange(e.target.value);
                                }}>
                                <option value="">Select State</option>
                                {stateList.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>

                            {/* <Col className="dropdown-style">
                                <Select
                                    className="search-select-style"
                                    classNamePrefix="select"
                                    placeholder="Select State"
                                    name="color"
                                    onChange={(val) => {
                                        props.setStateValue(val.value)
                                    }}
                                    options={stateList.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                />
                            </Col> */}

                            <select
                                className='select-design'
                                value={props.courseValue} 
                                onChange={(e) => props.setCourseValue(e.target.value)}>
                                <option value="">Select Course</option>
                                {options.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                            {/* <Col className="dropdown-style">
                                <Select
                                    className="search-select-style"
                                    classNamePrefix="select"
                                    placeholder="Select Course"
                                    name="color"
                                    onChange={(val) => props.setCourseValue(val.value)}
                                    options={options}
                                />
                            </Col> */}

                            <button onClick={() => {
                                // if( props.stateValue === "" || props.courseValue === ""){
                                //     return (
                                //         alert("Provide all the search field!")
                                //     )
                                // }
                                props.onClick()
                            }} className='header-tab-search-btn'>Search</button>
                        </Row>
                    )
                }

                {
                    isCity && (
                        <Row className='search-container'>
                            {/* <Col className="dropdown-style"> */}
                            <select
                                className='select-design'
                                value={props.stateValue} 
                                onChange={(e) => {
                                    props.setCityValue("");
                                    props.setStateValue(e.target.value);
                                    onStateChange(e.target.value);
                                }}>
                                <option value="">Select State</option>
                                {stateList.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                            {/* </Col> */}

                            {/* <Col className="dropdown-style"> */}
                            <select
                                className='select-design'
                                value={props.cityValue} 
                                onChange={(e) => props.setCityValue(e.target.value)}>
                                <option value="">Select City</option>
                                {cityList.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                            {/* </Col> */}

                            {/* <Col className="dropdown-style"> */}
                            {/* <Select
                                    className="search-select-style"
                                    classNamePrefix="select"
                                    placeholder="Select Course"
                                    name="color"
                                    onChange={(val) => props.setCourseValue(val.value)}
                                    options={options}
                                /> */}
                            <select
                                className='select-design'
                                value={props.courseValue} 
                                onChange={(e) => props.setCourseValue(e.target.value)}>
                                <option value="">Select Course</option>
                                {options.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                            {/* </Col> */}


                            <button onClick={() => {
                                if( props.stateValue === "" || props.cityValue === "" || props.courseValue === ""){
                                    return (
                                        alert("Provide all the search field!")
                                    )
                                }
                                props.onClick()}} className='header-tab-search-btn'>Search</button>
                        </Row>
                    )
                }

                {
                    isSchool && (
                        <div className='searching-style'>
                            <Row className='search-container'>

                                <select
                                    className='select-design'
                                    value={props.stateValue} 
                                    onChange={(e) => {
                                        props.setCityValue("")
                                        props.setSchoolValue("")
                                        props.setGradeValue("")
                                        props.setStateValue(e.target.value);
                                        onStateChange(e.target.value);
                                    }}>
                                    <option value="">Select State</option>
                                    {stateList.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>

                                {/* <Col className="dropdown-style">
                                    <Select
                                        className="search-select-style"
                                        classNamePrefix="select"
                                        placeholder="Select State"
                                        name="color"
                                        value={props.stateValue}
                                        onChange={(val) => {
                                            props.setCityValue("")
                                            props.setSchoolValue("")
                                            props.setGradeValue("")
                                            props.setStateValue(val.value)
                                            onStateChange(val)
                                        }}
                                        options={stateList.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        }))}
                                    />
                                </Col> */}

                                <select
                                    className='select-design'
                                    value={props.cityValue} 
                                    onChange={(e) => {
                                        props.setSchoolValue("")
                                        props.setGradeValue("")
                                        props.setCityValue(e.target.value)
                                        onCityChange(e.target.value)
                                    }}>
                                    <option value="">Select City</option>
                                    {cityList.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>

                                {/* <Col className="dropdown-style">
                                    <Select
                                        className="search-select-style"
                                        classNamePrefix="select"
                                        placeholder="Select City"
                                        name="color"
                                        value={props.cityValue}
                                        onChange={(val) => {
                                            props.setSchoolValue("")
                                            props.setGradeValue("")
                                            props.setCityValue(val.value)
                                            onCityChange(val)
                                        }}
                                        options={cityList.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        }))}
                                    />
                                </Col> */}

                                <select
                                    className='select-design'
                                    value={props.schoolValue} 
                                    onChange={(e) => {
                                        props.setGradeValue("")
                                        props.setSchoolValue(e.target.value)
                                    }}>
                                    <option value="">Select School</option>
                                    {schoolList.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>

                                {/* <Col className="dropdown-style">
                                    <Select
                                        className="search-select-style"
                                        classNamePrefix="select"
                                        placeholder="Select School"
                                        name="color"
                                        value={props.schoolValue}
                                        onChange={(val) => {
                                            props.setGradeValue("")
                                            props.setSchoolValue(val.value)
                                        }}
                                        options={schoolList.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        }))}
                                    />
                                </Col> */}
                            </Row>



                            <Row className='search-container'>
                                <select
                                    className='select-design'
                                    value={props.courseValue} 
                                    onChange={(e) => props.setCourseValue(e.target.value)}>
                                    <option value="">Select Course</option>
                                    {options.map((item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                                {/* <Col className="dropdown-style">
                                    <Select
                                        className="search-select-style"
                                        classNamePrefix="select"
                                        placeholder="Select Course"
                                        name="color"

                                        onChange={(val) => props.setCourseValue(val.value)}
                                        options={options}
                                    />
                                </Col> */}

                                {/* <select
                                    className='select-design'
                                    value={props.gradeValue}
                                    onChange={(e) => props.setGradeValue(e.target.value)}>
                                    <option value="">Select Grade</option>
                                    {gradeList.map((item, index) => (
                                        <option key={index} value={item.value}>{item.value}</option>
                                    ))}
                                </select> */}

                                {/* <Col className="dropdown-style">
                                    <Select
                                        className="search-select-style"
                                        classNamePrefix="select"
                                        placeholder="Select Grade"
                                        name="color"
                                        value={props.gradeValue}
                                        onChange={(val) => {
                                            props.setGradeValue(val.value)
                                        }}
                                        options={gradeList.map((item) => ({
                                            label: item.value,
                                            value: item.value,
                                        }))}
                                    />
                                </Col> */}

                                <button onClick={() =>{
                                     if( props.stateValue === "" || props.cityValue === "" || props.courseValue === "" ||  props.schoolValue === "" ){
                                        return (
                                            alert("Provide all the search field!")
                                        )
                                    }
                                    props.onClick()
                                    }} 
                                    className='header-tab-search-btn'>Search</button>
                            </Row>
                        </div>
                    )
                }
            </Row>
        </Container>
    )
}

export default HeaderTab