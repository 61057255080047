export const countryData = [
    {
      id: '1',
    //   img: indiaLogo,
      value: 'India',
      code: '+91'
    },
    // {
    //   id: '2',
    //   img: Ghana,
    //   value: 'Ghana',
    // },
    {
      id: '2',
    //   img: singapore,
      value: 'Singapore',
      code: '+65'
    },
    {
      id: '3',
    //   img: NewZealand,
      value: 'NZ',
      code: '+64'
    },
  
    {
      id: '4',
    //   img: australia,
      value: 'Australia',
      code: '+61'
    },
    {
      id: '5',
    //   img: canada,
      value: 'Canada',
      code: '+1'
    },
  ];
  