import React, { useEffect, useState } from 'react'
import { postApiCallStringyfy } from '../utils/api';
import { Col, Container, Row } from 'react-bootstrap'

const Testing = () => {
    const [data, setData] = useState([])

    
    const loadData = async () => {
        console.log("Asdasd");
        const res = await postApiCallStringyfy('/leaderboard/getRank', {
            "country_id": "1",
            "course_id": "27",
            "offset": "0",
            "limit": "100"
        });
        setData(res[0].toppers);

    };

    const handelInfiniteScroll = async () => {
        console.log("scrollHeight" + document.documentElement.scrollHeight);
    }
    useEffect(() => {
        loadData()
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);

    return (
        <div>
           {
                            data.slice(3).map((item, index) => (
                                <div key={index}>
                                    {
                                        item.emi_type === "premium" ?
                                            (
                                                <Row className='rank-listing-container container'>
                                                    <Col className='start-flex-align'>
                                                        <Row><img src={item.profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                                                        <div >
                                                            <div className='ranklist-detail-contain'>
                                                                <Row className='ranklist-student-name'>{item.first_name}  {item.last_name}</Row>
                                                                <Row className='ranklist-course-name'>{item.course_name}</Row>
                                                            </div>
                                                            <br />
                                                            <Row className='ranklist-school-name'>{item.site_name}</Row>
                                                        </div>
                                                    </Col>

                                                    <Row className='rank-contain'>
                                                        <Col className='rank-name'>
                                                            <Row>Rank :</Row>
                                                        </Col>
                                                        <Col className='ranklist-rank-no'>
                                                            <Row>{item.rank}</Row>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            ) :
                                            (
                                                <Row className='rank-listing-container-base container'>
                                                    <Col className='start-flex-align'>
                                                        <Row><img src={item.profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                                                        <div >
                                                            <div className='ranklist-detail-contain'>
                                                                <Row className='ranklist-student-name'>{item.first_name}  {item.last_name}</Row>
                                                                <Row className='ranklist-course-name'>{item.course_name}</Row>
                                                            </div>
                                                            <br />
                                                            <Row className='ranklist-school-name'>{item.site_name}</Row>
                                                        </div>
                                                    </Col>

                                                    <Row className='rank-contain'>
                                                        <Col className='rank-name'>
                                                            <Row>Rank :</Row>
                                                        </Col>
                                                        <Col className='ranklist-rank-no'>
                                                            <Row>{item.rank}</Row>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            )
                                    }
                                </div>
                            ))
                        }

        </div>
    )
}

export default Testing