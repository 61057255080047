import React, { useEffect, useState } from "react";
import './leaderboard.css';
import Topranker from './components/School/Topranker';
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { postApiCallStringyfy } from "./utils/api";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Header from "./components/Header";
import HeaderTab from "./components/HeaderTab";
import TopThreeRanking from "./components/TopThreeRanking";
import OtherRanks from "./components/OtherRanks";

const School = () => {

  const { schoolID } = useParams();

  const options = [
    { value: "27", label: "BLOCKLY BEGINNER" },
    { value: "26", label: "BLOCKLY ADVANCE" },
    { value: "7", label: "PYTHON BEGINNER" },
    { value: "29", label: "PYTHON ADVANCE" },
  ];

  const naviagate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [optionVal, seOptionVal] = useState("7")
  const handleOptionChange = (val) => {
    seOptionVal(val)
    naviagate(`/leaderBoard/${schoolID}/${val}`);
    getData(val)

  }
  const getData = async (course) => {
    setIsLoading(true);
    const res = await postApiCallStringyfy('/leaderboard/school', { "school_id": schoolID, "course_id": course });
    setData(res[0].toppers);
    setIsLoading(false);
  }

  // const [searchQuery, setSearchQuery] = useState('');

  // const filteredData = data.filter(item => item.first_name.toLowerCase().includes(searchQuery.toLowerCase()));

  useEffect(() => {
    getData(optionVal)
  }, [])
  return (
    <>
      <LoadingOverlay className="my-loading-overlay" active={isLoading} text='Loading your content...' spinner>

        <Header />
        <HeaderTab />
        {data.length !== 0 && (
          <Container className="body-container">
            <Row className="dropdown-container">
              <Col className="dropdown-style">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="BLOCKLY BEGINNER"
                  name="color"
                  onChange={(val) => handleOptionChange(val.value)}
                  options={options}
                />
              </Col>
            </Row>
            <TopThreeRanking data={data} />
            <OtherRanks data={data} />

          </Container>)}
        <div className="footer">
          <a
            href="https://icode.org/contact/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <p
              style={{ color: "white", marginRight: "10px", marginTop: "20px" }}
            >
              Contact Us{" "}
            </p>
          </a>
          <p>
            <img src="footer.png" alt="footer" />
          </p>
        </div>

 {/* <input type="text" value={searchQuery} onChange={event => setSearchQuery(event.target.value)} /> */}
        {/* 
      {filteredData.length > 0 ? (
        filteredData.map(item => <div key={item.id}>{item.first_name}</div>)
      ) : (
        <div>No results found</div>
      )} */}
        {/* <Container className="bg-dark">
          <Row className="header">
            <Col >
              <img src="/logo2.png" width={212} height={120} alt="icode" />
            </Col>
            <Col className="dropdown">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="BLOCKLY BEGINNER"
                name="color"
                onChange={(val) => handleOptionChange(val.value)}
                options={options}
              />
            </Col>
          </Row>
        </Container>
       
        <h1 className="heading">SCHOOL LEADERBOARD</h1>
        <Topranker data={data} /> */}


      </LoadingOverlay>
    </>
  )
}

export default School