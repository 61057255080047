import React, { useState, useEffect } from 'react'
import '../css/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import InfiniteScroll from "react-infinite-scroll-component";
import { useMediaQuery } from 'react-responsive';
import BaseTopThree from './BaseTopThree';

const OtherRanks = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <>

            <Container className='center-flex-contain'>
                <Container className='rank-listing-main'>

                    {props.searchTerm === "" ?
                        (<InfiniteScroll
                            dataLength={props.data.length}
                            next={props.onClick}
                            hasMore={props.hasMore}
                            loader={<h4 style={{ color: 'white' }}>Loading...</h4>}
                            endMessage={<h4 style={{ color: 'white' }}>No more data</h4>}
                        >

                            {props.data[0] && props.data[0].emi_type === 'base' && <BaseTopThree item={props.data[0]} />}
                            {props.data[1] && props.data[1].emi_type === 'base' && <BaseTopThree item={props.data[1]} />}
                            {props.data[2] && props.data[2].emi_type === 'base' && <BaseTopThree item={props.data[2]} />}
                            {
                                props.data.length !== 0 && props.data.slice(3).map((item, index) => {
                                    let displayName = isMobile ? `${item.first_name} ${item.last_name}` : `${item.first_name} ${item.last_name}`;

                                    if (!isMobile && displayName.length > 18) {
                                        displayName = displayName.slice(0, 18) + "..";
                                    }
                                    // const displayName = isMobile ?
                                    //     `${item.first_name} ${item.last_name}` :
                                    //     `${(item.first_name + " " + item.last_name).slice(0, 19) }`;

                                    return <div key={index}>
                                        {
                                            item.emi_type === "premium" ?
                                                (
                                                    <Row className='rank-listing-container'>

                                                        <Col className='start-flex-align '>
                                                            <Row className='rank-contain-2'>
                                                                <Col className='rank-name'>
                                                                    <Row>Rank :</Row>
                                                                </Col>
                                                                <Col className='ranklist-rank-no'>
                                                                    <Row>{item.rank}</Row>
                                                                </Col>
                                                            </Row>
                                                            <Row><img src={item.profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                                                            <div >
                                                                <div className='ranklist-detail-contain'>
                                                                    <Row className='ranklist-student-name'>{displayName}</Row>
                                                                    <Row className='ranklist-course-name'>{item.course_name}</Row>
                                                                </div>
                                                                <br />
                                                                <Row className='ranklist-school-name'>{item.site_name}</Row>
                                                            </div>
                                                        </Col>

                                                        <Row className='rank-contain'>
                                                            <Col style={{
                                                                display: 'flex',
                                                                flexDirection: 'row'
                                                            }}>
                                                                <Col className='rank-name'>
                                                                    <Row>Score:</Row>
                                                                </Col>
                                                                <Col className='ranklist-rank-no'>
                                                                    <Row>{item.highest_score}</Row>
                                                                </Col>
                                                            </Col>
                                                            <Col style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                marginLeft: 10
                                                            }}>
                                                                <Col className='rank-name'>
                                                                    <Row>Rank:</Row>
                                                                </Col>
                                                                <Col className='rank-name ranklist-rank-no'>
                                                                    <Row>{item.rank}</Row>
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                ) :
                                                (
                                                    <Row className='rank-listing-container-base'>

                                                        <Col className='start-flex-align '>
                                                            <Row className='rank-contain-base'>
                                                                {/* <Col className='rank-name'>
                                                                    <Row>Rank :</Row>
                                                                </Col> */}
                                                                <Col className='ranklist-rank-no-base'>
                                                                    <Row>Not</Row>
                                                                    <Row>Ranked</Row>
                                                                </Col>
                                                            </Row>
                                                            <Row><img src={item.profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                                                            <div >
                                                                <div className='ranklist-detail-contain'>
                                                                    <Row className='ranklist-student-name'>{displayName}</Row>
                                                                    <Row className='ranklist-course-name'>{item.course_name}</Row>
                                                                </div>
                                                                <br />
                                                                <Row className='ranklist-school-name'>{item.site_name}</Row>
                                                            </div>
                                                        </Col>

                                                        <Row className='rank-contain'>
                                                            <Col style={{
                                                                display: 'flex',
                                                                flexDirection: 'row'
                                                            }}>
                                                                <Col className='rank-name'>
                                                                    <Row>Score:</Row>
                                                                </Col>
                                                                <Col className='ranklist-rank-no'>
                                                                    <Row>{item.highest_score}</Row>
                                                                </Col>
                                                            </Col>
                                                            <Col style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                marginLeft: 10
                                                            }}>
                                                                <Col className='rank-name'>
                                                                    <Col className='ranklist-rank-no-base'>
                                                                        <Row>Not</Row>
                                                                        <Row>Ranked</Row>
                                                                    </Col>
                                                                </Col>
                                                                {/* <Col className='rank-name ranklist-rank-no'>
                                                                    <Row>{item.rank}</Row>
                                                                </Col> */}
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                )
                                        }
                                    </div>
                                })
                            }
                        </InfiniteScroll>)
                        :
                        props.data.length !== 0 && props.data.map((item, index) => (
                            <div key={index}>
                                {
                                    item.emi_type === "premium" ?
                                        (
                                            <Row className='rank-listing-container'>

                                                <Col className='start-flex-align '>
                                                    <Row className='rank-contain-2'>
                                                        <Col className='rank-name'>
                                                            <Row>Rank :</Row>
                                                        </Col>
                                                        <Col className='ranklist-rank-no'>
                                                            <Row>{item.rank}</Row>
                                                        </Col>
                                                    </Row>
                                                    <Row><img src={item.profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                                                    <div >
                                                        <div className='ranklist-detail-contain'>
                                                            <Row className='ranklist-student-name'>{item.first_name}  {item.last_name}</Row>
                                                            <Row className='ranklist-course-name'>{item.course_name}</Row>
                                                        </div>
                                                        <br />
                                                        <Row className='ranklist-school-name'>{item.site_name}</Row>
                                                    </div>
                                                </Col>

                                                <Row className='rank-contain'>
                                                    <Col style={{
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }}>
                                                        <Col className='rank-name'>
                                                            <Row>Score:</Row>
                                                        </Col>
                                                        <Col className='ranklist-rank-no'>
                                                            <Row>{item.highest_score}</Row>
                                                        </Col>
                                                    </Col>
                                                    <Col style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        marginLeft: 10
                                                    }}>
                                                        <Col className='rank-name'>
                                                            <Row>Rank:</Row>
                                                        </Col>
                                                        <Col className='rank-name ranklist-rank-no'>
                                                            <Row>{item.rank}</Row>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        ) :
                                        (
                                            <Row className='rank-listing-container-base'>

                                                <Col className='start-flex-align '>
                                                    <Row className='rank-contain-2'>
                                                        <Col className='ranklist-rank-no-base'>
                                                            <Row>Not</Row>
                                                            <Row>Ranked</Row>
                                                        </Col>
                                                    </Row>
                                                    <Row><img src={item.profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                                                    <div >
                                                        <div className='ranklist-detail-contain'>
                                                            <Row className='ranklist-student-name'>{item.first_name}  {item.last_name}</Row>
                                                            <Row className='ranklist-course-name'>{item.course_name}</Row>
                                                        </div>
                                                        <br />
                                                        <Row className='ranklist-school-name'>{item.site_name}</Row>
                                                    </div>
                                                </Col>

                                                <Row className='rank-contain'>
                                                    <Col style={{
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }}>
                                                        <Col className='rank-name'>
                                                            <Row>Score:</Row>
                                                        </Col>
                                                        <Col className='ranklist-rank-no'>
                                                            <Row>{item.highest_score}</Row>
                                                        </Col>
                                                    </Col>
                                                    <Col style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        marginLeft: 10
                                                    }}>
                                                        <Col className='rank-name'>
                                                            <Col className='ranklist-rank-no-base'>
                                                                <Row>Not</Row>
                                                                <Row>Ranked</Row>
                                                            </Col>
                                                        </Col>
                                                        {/* <Col className='rank-name ranklist-rank-no'>
                                                            <Row>{item.rank}</Row>
                                                        </Col> */}
                                                    </Col>
                                                </Row>
                                            </Row>
                                        )
                                }
                            </div>
                        ))
                    }
                </Container>
            </Container>

        </>
    )
}

export default OtherRanks