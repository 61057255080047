import React from 'react'
import '../css/style.css'
import { Col, Row } from 'react-bootstrap'

const BaseTopThree = ({ item }) => {
    return (
        <div>


            < Row className='rank-listing-container-base'>

                <Col className='start-flex-align '>
                    <Row className='rank-contain-2'>
                        <Col className='ranklist-rank-no-base'>
                            <Row>Not</Row>
                            <Row>Ranked</Row>
                        </Col>
                    </Row>
                    <Row><img src={item.profilepic} alt="profile" width={90} height={90} className='top-three-image' /></Row>
                    <div >
                        <div className='ranklist-detail-contain'>
                            <Row className='ranklist-student-name'>{item.first_name}  {item.last_name}</Row>
                            <Row className='ranklist-course-name'>{item.course_name}</Row>
                        </div>
                        <br />
                        <Row className='ranklist-school-name'>{item.site_name}</Row>
                    </div>
                </Col>

                <Row className='rank-contain'>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <Col className='rank-name'>
                            <Row>Score:</Row>
                        </Col>
                        <Col className='ranklist-rank-no'>
                            <Row>{item.highest_score}</Row>
                        </Col>
                    </Col>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 10
                    }}>
                        <Col className='rank-name'>
                            <Col className='ranklist-rank-no-base'>
                                <Row>Not</Row>
                                <Row>Ranked</Row>
                            </Col>
                        </Col>
                        {/* <Col className='rank-name ranklist-rank-no'>
            <Row>{item.rank}</Row>
        </Col> */}
                    </Col>
                </Row>
            </Row>

        </div >
    )
}

export default BaseTopThree