import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./leaderboard.css";
import School from "./school";
import National from "./National";
import NotFoundPage from "./components/NotFoundPage";
import { countryData } from "./utils/Constants";
import Header from "./components/Header";
import HeaderTab from "./components/HeaderTab";
import Testing from "./components/Testing";


function App() {

  return (
    <>
      <Container className="bg-dark">
        
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<CheckNational />} />
            <Route path="/test" element={<Testing />} />
            <Route path="/national" element={<CheckNational />} />
            <Route path="/leaderBoard/:schoolID/:courseID" element={<School />} />
            <Route path="/leaderBoard/:schoolID/" element={<School />} />
            <Route path="national/:countryName" element={<CountrySchool />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </>
  );
}

function IterateCountry() {
  const { countryName } = useParams();
  const countryList = countryData
  for (let i = 0; i < countryList.length; i++) {
    if (countryName.toLowerCase() === countryList[i].value.toLowerCase())
      return countryList[i].id;
  }
  return false;
}

function CountrySchool() {
  // const { countryName } = useParams();
  // const [currentId, setCurrentId] = useState(IterateCountry());

  const checkCountry = IterateCountry();
  // useEffect(() => {
  //   setCurrentId(IterateCountry());
  //   alert(IterateCountry())
  // }, [countryName]);

  if (checkCountry !== false) {
    // window.location = countryName + "/" + currentId;
    return <National />;
  } else {
    return <NotFoundPage />;
  }
}

function CheckNational() {
  // naviagate('/national/india');
  window.location = '/national/india'
}

export default App;
